<template>
    <div style="background-color: #F5F5F5;
        padding-bottom: 80px;">
        <div class="zx-banner-bar" id="index">
        </div>
        <div class="zx-recruit-search-box">
            <el-input class="zx-input" placeholder="输入关键字" prefix-icon="el-icon-search" type="text" v-model.trim="searchKey">
                <el-button class="zx-button" slot="append">搜索</el-button>
            </el-input>
            <template v-if="dataList.length > 0 ">
                <div class="recruit-list" v-for="(item, index) in dataList" :key="index" @click="gotoDetail(item)">
                    <h3 style="height: 80px;line-height: 80px;color: #000;font-size: 24px;font-weight: 600">
                        {{item.recruitName}}
                    </h3>
                    <div style="display: flex;align-items: center;font-size: 18px;color: #999cB0 ">
                        <img src="../../assets/product/recurit-logo.png" alt="">
                        {{`厦门 | ${item.years} | 本科 | ${item.salaryRange}` }}
                    </div>
                    <img class="share-icon" src="../../assets/product/zx-share-icon.png" alt="">
                </div>
            </template>
            <div v-else>
                <h3 style="height: 80px;line-height: 80px;color: #000;font-size: 24px;font-weight: 600">
                    暂无相关
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    import JsonData from '../../json/data.json'

    export default {
        name: 'App',
        data(){
            return {
                searchKey: '',
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ]
            }
        },
        computed:{
            dataList(){
                if(this.searchKey == ''){
                    return JsonData.data
                }else{
                    return JsonData.data.filter(x=>{
                        console.log(x.recruitName);
                        return x.recruitName.includes(this.searchKey)
                    })
                }

            }
        },
        methods:{
            gotoDetail(item){
                console.log(item);
                this.$router.push({
                    path:'/detail',
                    query:item
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .zx-banner-bar {
        height: 600px;
        background: url("../../assets/product/zx-recruit-bg.png") no-repeat;
        background-size:cover ;
    }
</style>
<style lang="scss" scoped>
    .zx-aboutUs-cont-bar{
        height: 951px;
        width: 1200px;
        position: relative;
        margin: 0 auto;
        .zx-aboutUs-first-box{
            box-sizing: border-box;
            position: absolute;
            width: 666px;
            height: 425px;
            left: 0px;
            top: 316px;
            background: #FFFFFF;
            border: 1px solid rgba(153, 172, 176, 0.25);
            box-shadow: 0px 54px 54px rgba(153, 172, 176, 0.15);
            border-radius: 4px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                text-indent: 2em;
                width: 550px;
                height: 320px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 214%;
                /* or 43px */

                text-align: center;

                color: rgba(18, 18, 18, 0.75);

            }
        }
        img{
            position: absolute;
            right: 0;
            top: 210px;
            z-index: 0;
        }
        .zx-aboutUs-cont-title{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 56px;
            color: #121212;
            position: absolute;
            left: 253px;
            top: 140px;
            &:after{
                content: '';
                position: absolute;
                width: 70px;
                height: 6px;
                background: #305DC4;
                border-radius: 11px;
                left: 50%;
                margin-left: -35px;
                top: 90px;
            }
        }
    }

    .zx-prod-cont-bar{
    }

    .zx-recruit-search-box{
        margin: 30px auto;
        width: 1200px;
        background: #FFFFFF;
        padding: 30px;
    }
    .zx-input ::v-deep .el-input__inner{
        width: 1090px;
        height: 56px;
        background: #FFFFFF;
        border: 1px solid rgba(153, 156, 176, 0.45);
        padding-left: 50px;
        font-size: 20px;
        border-right: 0;
        border-radius: 10px 0 0 10px;
    }
    .zx-input ::v-deep .el-input__prefix{
        left: 15px;
        font-size: 20px;
        margin-bottom: -5px;
        color: #121212;
    }
    .zx-input ::v-deep .el-input-group__append{
        background: #121212;
        border-radius: 0 4px 4px 0;
        color: #fff;
        width: 116px;
        font-size:20px;
    }
    .recruit-list{
        text-align: left;
        border-bottom: 1px solid rgba(153, 156, 176, 0.15);
        cursor: pointer;
        transition: all .5s;
        padding: 0 30px 30px;

        position: relative;
        img{
            margin-right: 20px;
        }
        .share-icon{
            position: absolute;
            right: 0;
            top: 30px;
        }
        &:hover{
            background-color: #f8f8f8;
            transition: all .5s;
        }
    }
</style>
